import { Provider } from "./src/services/provider"

import "./src/styles/libs/sanitize.scss"
import "./static/fonts/global-fonts.css"
import "./src/styles/global-styles.scss"
import "./src/styles/global-utilities.scss"

// ---------------------------------------- | Image Polyfill (IntersectionObserver)

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

export const onInitialClientRender = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const utmKeys = Array.from(urlParams.keys())
  const trackedUtms = [
    "utm_medium",
    "utm_source",
    "utm_campaign",
    "utm_content",
    "utm_keyword",
    "utm_adname"
  ]

  if (trackedUtms.some((e) => utmKeys.includes(e))) {
    trackedUtms.forEach((utm) => {
      if (utmKeys.includes(utm)) {
        sessionStorage.setItem(utm, urlParams.get(utm))
      } else {
        sessionStorage.removeItem(utm)
      }
    })
  }
}

// ---------------------------------------- | Scroll To Anchor

export const onRouteUpdate = ({ location }) => scrollToAnchor(location)

/**
 * Smooth scroll to position of hash on page load.
 */
function scrollToAnchor(location, mainNavHeight = 0) {
  if (location && location.hash) {
    const item = document.querySelector(`${location.hash}`)
    if (!item) return

    const itemTop = item.offsetTop

    window.scrollTo({
      behavior: "smooth",
      top: itemTop - mainNavHeight
    })
  }

  return true
}

// ---------------------------------------- | Global State Management

export const wrapRootElement = Provider
