// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-thank-you-js": () => import("./../../../src/pages/contact-thank-you.js" /* webpackChunkName: "component---src-pages-contact-thank-you-js" */),
  "component---src-templates-page-about-about-js": () => import("./../../../src/templates/page/about/about.js" /* webpackChunkName: "component---src-templates-page-about-about-js" */),
  "component---src-templates-page-blank-template-blank-template-js": () => import("./../../../src/templates/page/blank-template/blank-template.js" /* webpackChunkName: "component---src-templates-page-blank-template-blank-template-js" */),
  "component---src-templates-page-brands-js": () => import("./../../../src/templates/page/brands.js" /* webpackChunkName: "component---src-templates-page-brands-js" */),
  "component---src-templates-page-contact-contact-js": () => import("./../../../src/templates/page/contact/contact.js" /* webpackChunkName: "component---src-templates-page-contact-contact-js" */),
  "component---src-templates-page-home-js": () => import("./../../../src/templates/page/home.js" /* webpackChunkName: "component---src-templates-page-home-js" */),
  "component---src-templates-page-product-category-js": () => import("./../../../src/templates/page/product-category.js" /* webpackChunkName: "component---src-templates-page-product-category-js" */),
  "component---src-templates-page-product-detail-product-detail-js": () => import("./../../../src/templates/page/product-detail/product-detail.js" /* webpackChunkName: "component---src-templates-page-product-detail-product-detail-js" */),
  "component---src-templates-page-product-section-product-section-js": () => import("./../../../src/templates/page/product-section/product-section.js" /* webpackChunkName: "component---src-templates-page-product-section-product-section-js" */),
  "component---src-templates-page-resource-detail-resource-detail-js": () => import("./../../../src/templates/page/resource-detail/resource-detail.js" /* webpackChunkName: "component---src-templates-page-resource-detail-resource-detail-js" */),
  "component---src-templates-page-resources-resources-js": () => import("./../../../src/templates/page/resources/resources.js" /* webpackChunkName: "component---src-templates-page-resources-resources-js" */)
}

